import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "e8e8f53b-e783-4a49-b24d-706f7e5722fb",
    videoLibraryId: "128088",
    streamCdnUrl: "https://vz-4d3b8c40-574.b-cdn.net",
    streamApiKey: "70fb1b3c-ac4c-4f35-9181117d71e2-2f39-42d9",
    storageApiPassword: "d1e6788e-750f-4cfa-ab4a5e7aefa5-2aa1-4619",
    cdnUrl: "https://metavision-pwa.b-cdn.net",
    devFolder: "/metavision-pwa",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",
    
    // top level menu
    assetsMenuOrder: [
        '26 Vista Showcase',
        'Florian Showcase',
        'Ritz Carlton Marina Showcase',
        'Land Subdivision Showcase',
        'PA Health Connect Showcase'
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf'
    ],
  
    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Vista 3 bed'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/5phsXhF-B'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed2Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Florian Type A'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/pKlyrB_EF8KW'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed3Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Marina Arm A'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/D_0nXoSSLNt'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed4Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Land Subdivision Demo'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/2T2ciQRdd'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed5Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [ ],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };